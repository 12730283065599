











import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({ name: "photo-view" })
export default class PhotoView extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  /* Variables */

  /* Computed */

  /* Methods */

  @Emit("input")
  close() {
    return null;
  }

  /* Life-cycle Methods */
}
